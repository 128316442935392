<template>
    <div :class="'group relative px-2 '+(content.data.layout==='rectangle' ? ' col-span-2 ': ' col-span-1 ') ">

        <jet-nav-link :href="content.data.url" class="cursor-pointer" v-if="!editable">
            <div
                class="relative h-80 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 ">
                <img v-if="isString(content.data.file)" :src="asset(content.data.file)" :alt="content.data.name"
                     class="h-full w-full object-cover object-center">
            </div>
            <h2 class="mt-6 text-sm text-gray-700 dark:text-gray-300  ">

                <span class="absolute inset-0"></span>
                {{ content.data.category }}

            </h2>
            <p class="text-base font-semibold text-gray-900 mb-10 dark:text-white "> {{ content.data.name }}</p>
        </jet-nav-link>
        <div v-else>
            <div class="relative  h-80 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75">
                <img v-if="isString(content.data.file)" :src="asset(content.data.file)" :alt="content.data.name"
                     class="h-full w-full object-cover object-center">
            </div>
            <h3 class="mt-6 text-sm text-gray-700  dark:text-gray-300">
                <span class="absolute inset-0"></span>
                {{ content.data.category }}
            </h3>
            <p class="text-base font-semibold text-gray-900 mb-10  dark:text-gray-300"> {{ content.data.name }}</p>
        </div>

    </div>



</template>

<script>
import JetNavLink from '@/Jetstream/NavLink.vue'

export default {
    emits: ["clicked"], //
    name: "ContentHomepageBlock",
    components: {
        JetNavLink,
    },
    props: {
        content: Object,
        contents: Object,
        editable: Boolean
    },
    data() {
        return {


        }
    },
    methods: {

        isString(v) {
            return v !== undefined && typeof v === "string";
        }
    },
}
</script>
